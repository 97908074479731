<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9994;">
        <md-content>
            <div class="page-content" style="height: 350px; min-height: 350px;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <div class="group-box" style="margin-top:10px; height: 200px;">
                                        <div class="group-title">THÔNG TIN VENDOR</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="info">
                                                        <div class="item">
                                                            <span class="label">Công ty vận chuyển:</span> 
                                                            <span class="value">{{ entity.vendor.companyAlias }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Nhân viên điều xe:</span> 
                                                            <span class="value">{{ entity.staff.fullName }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Ngày tạo:</span> 
                                                            <span class="value">{{ entity.submitDate }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Ngày thực hiện:</span> 
                                                            <span class="value">{{ entity.jobDate }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Lái xe:</span> 
                                                            <span class="value">{{ entity.driver }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Số xe:</span> 
                                                            <span class="value">{{ entity.vehicle }}</span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div class="group-box" style="margin-top:10px; height: 200px;">
                                        <div class="group-title">THÔNG TIN CONT/KIỆN HÀNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="info">
                                                        <div class="item">
                                                            <span class="label">Mã đơn hàng:</span> 
                                                            <span class="value">{{ entity.order.orderCode }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Số con't/Kiện:</span> 
                                                            <span class="value">{{ entity.orderDetail.serial }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Đơn vị:</span> 
                                                            <span class="value">{{ entity.orderDetail.unit.unitName }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Nơi nhận cont/kiện:</span> 
                                                            <span class="value">
                                                                <span v-if="entity.orderDetail.typeOfTransport == 1">{{ entity.transitDelivery }}</span>
                                                                <span v-else-if="entity.orderDetail.typeOfTransport == 2">{{ entity.transitReceipt }}</span>
                                                            </span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Nơi đóng/trả hàng:</span> 
                                                            <span class="value">
                                                                <span v-if="entity.orderDetail.typeOfTransport == 1">{{ entity.placeOfReceiptGoods }}</span>
                                                                <span v-else-if="entity.orderDetail.typeOfTransport == 2">{{ entity.placeOfDeliveryGoods }}</span>
                                                            </span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Nơi trả cont/kiện:</span> 
                                                            <span class="value">
                                                                <span v-if="entity.orderDetail.typeOfTransport == 1">{{ entity.placeOfReceiptCont }}</span>
                                                                <span v-else-if="entity.orderDetail.typeOfTransport == 2">{{ entity.placeOfDeliveryCont }}</span>
                                                            </span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import common from '../../../mixins';
import { mapActions } from 'vuex';
import messageBox from '../../../utils/messageBox';
import jobVendorService from '../../../api/jobVendorService';

export default ({
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            loading: false,
            entity: { id: 0, order: { orderCode: '' }, vendor: { companyAlias: '' }, orderDetail: { serial: '', unit: { unitName: '' } }, vendorId: 0, transportCost: 0, staffId: 0, staff: { fullName: '' }, jobCode: '', jobNumber: 0, submitDate: common.dateNow, jobDate: common.dateNow },
        }
    },
    created(){
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        open(id){
            this.showDialog = true;
            this.title = "Điều xe ngoài";
            this.id = id;
            this.getById();
        },

        close(){
            this.showDialog = false;
        },

        getById(){
            this.loading = true;
            this.staffName = '';
            this.vendorName = '';
            jobVendorService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loading = false; });
        },
    }
})
</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
    }
    .item {
        padding: 3px 5px;
    }
    .label {
        font-size: 15px;
        font-weight: bold;
        padding-right: 10px;
    }
    .value {
        font-size: 15px;
    }
</style>
