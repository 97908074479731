<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Thanh toán xe ngoài</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo lệnh điều xe</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="status">Trạng thái</label>
                                <md-select v-model="search.status" name="status" id="status">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option :value="1">Chưa giải chi</md-option>
                                    <md-option :value="2">Đã giải chi</md-option>
                                    <md-option :value="3">Đã quyết toán</md-option>
                                </md-select>
                            </md-field>
                        </div>
                       
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số cont</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 275px); overflow-x: auto; width: 100%;">
                        <table id="main-table" class="main-table" style="width: 2750px !important;">
                            <thead>
                                <tr>
                                    <th rowspan="2"  class="sticky-col" style="background-color: #DDDDDD; z-index: 999; width: 120px;">HÀNH ĐỘNG</th>
                                    <th rowspan="2"  class="sticky-col-2" style="width: 150px; background-color: #E6B9A6; z-index: 999; color: red;">MÃ ĐIỀU ĐỘNG</th>
                                    <th rowspan="2" style="width: 100px; background-color: #E6B9A6; z-index: 998; color: red;">TRẠNG THÁI</th>
                                    <th colspan="9" style="background-color: #FFD0D0; width: 30%;z-index: 998;">THÔNG TIN BILL/BOOKING</th>
                                    <th colspan="3" style="background-color: #BBE9FF; width: 30%;z-index: 998;">ĐỊA ĐIỂM GIAO NHẬN</th>
                                    <th colspan="3" style="background-color: #E0FBE2; width: 40%;z-index: 998;">THÔNG TIN ĐIỀU ĐỘNG</th>
                                    <th colspan="3" style="background-color: #7E8EF1; width: 40%;z-index: 998;">TỔNG HỢP THANH TOÁN</th>
                                </tr>
                                <tr v-if="loadding == false && pager.totalPages > 0">
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Ngày thực hiện</th>
                                    <th style="width: 150px; background-color: #FFD0D0;z-index: 998;">Khách hàng</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại hình</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">KL (tấn)</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Bill/Booking</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại cont</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số cont</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số seal</th>
                                    <th style="width: 100px; background-color: #FFD0D0; color: red;z-index: 998;">Closing time</th>
                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi nhận cont</th>
                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Kho đóng/rút hàng</th>
                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi trả cont</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số xe</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Tên lái xe</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số điện thoại</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Cước vận chuyển</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Chi hộ</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Phí dịch vụ</th>
                                    
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data" style="text-align: center;"><span >Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="'job-' + index" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="action sticky-col" style="z-index: 998; display: flex; justify-content: center; align-items: center; width: 120px;">
                                        <md-button v-if="!item.isExplain" @click="explain(item)" class="md-fab md-mini md-view">
                                            <i class="uil uil-usd-circle"></i>
                                            <md-tooltip>Giải chi</md-tooltip>
                                        </md-button>
                                        <md-button v-else-if="item.isExplain && !item.isInvoice" @click="explain(item)" class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa giải chi</md-tooltip>
                                        </md-button>
                                        <md-button v-if="item.isExplain && !item.isInvoice" @click="openCrudRefund(item.id)" class="md-fab md-mini md-view">
                                            <i class="uil uil-bill"></i>
                                            <md-tooltip>Phải trả</md-tooltip>
                                        </md-button>
                                        <router-link v-if="item.isInvoice" :to="'/refund/view/' + item.id">
                                            <md-button class="md-fab md-mini md-view">
                                                <i class="uil uil-eye"></i>
                                                <md-tooltip>Xem</md-tooltip>
                                            </md-button>
                                        </router-link>
                                    </td>
                                    <td class="sticky-col-2" style="z-index: 998;">
                                        <a @click="viewJob(item.id)">{{  item.jobCode }}</a>
                                    </td>
                                    <td>
                                        <md-chip v-if="item.isInvoice" class="status status-green-accent">Đã quyết toán</md-chip>
                                        <md-chip v-else-if="item.isExplain" class="status status-violet">Đã giải chi</md-chip>
                                        <md-chip v-else class="status status-red">Chưa giải chi</md-chip>
                                    </td>
                                    <td>{{ item.jobDate }}</td>
                                    <td>{{ item.order.customer.companyAlias }}</td>
                                    <td>{{ getTypeOfTransport(item.orderDetail.typeOfTransport) }}</td>
                                    <td style="text-align: right;">{{ Math.round(item.orderDetail.kg/1000, 2) }}</td>
                                    <td>{{ item.order.booking.noBooking }}</td>
                                    <td>{{ getGoodsType(item.orderDetail.goodsTypeId) }}</td>
                                    <td>{{ item.orderDetail.serial }}</td>
                                    <td>{{ item.orderDetail.seal }}</td>
                                    <td>{{ item.order.booking.closingTime }}</td>
                                    <td>
                                        <span v-if="item.orderDetail.typeOfTransport == 1">{{ item.transitDelivery }}</span>
                                        <span v-else-if="item.orderDetail.typeOfTransport == 2">{{ item.transitReceipt }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.orderDetail.typeOfTransport == 1">{{ item.placeOfReceiptGoods }}</span>
                                        <span v-else-if="item.orderDetail.typeOfTransport == 2">{{ item.placeOfDeliveryGoods }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.orderDetail.typeOfTransport == 1">{{ item.placeOfReceiptCont }}</span>
                                        <span v-else-if="item.orderDetail.typeOfTransport == 2">{{ item.placeOfDeliveryCont }}</span>
                                    </td>
                                    <td>{{ item.vehicle }}</td>
                                    <td>{{ item.driver }}</td>
                                    <td>{{ item.phone }}</td>
                                    <td class="right">{{ formatNumber(item.transportCost) }}</td>
                                    <td class="right">{{ formatNumber(item.feeOnBehalf) }}</td>
                                    <td class="right">{{ formatNumber(item.feeService) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crudForm ref="crudForm" @refresh="refresh" />
        <addForm ref="addForm" @refresh="refresh" />
        <viewForm ref="viewForm" />
    </div>
</template>

<script>
    import jobVendorService from '../../../api/jobVendorService';
    import branchService from '../../../api/branchService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import crudForm from './Crud.vue';
    import addForm from './CrudRefund.vue';
    import viewForm from '../order-vehicle/ViewJobVendor.vue';

    export default {
        components: {
            crudForm,
            addForm,
            viewForm
        },
        metaInfo: {
            title: 'Danh sách điều xe'
        },
        data() {
            return {
                loadding: false,
                id: 0,
                customerType: common.companyType.customer,
                typeList: common.typeOfTransport,
                submitted: false,
                search: { pageIndex: 1, pageSize: 25, branchId: 0, status: 0, keyword: '', fromDate: this.getRangeDate(-7), toDate: common.dateNow, serial: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                checkedList: [],
                branchs: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            explain(obj){
                this.$refs.crudForm.open(obj);
            },

            viewJob(jobId){
                this.$refs.viewForm.open(jobId);
            },

            openCrudRefund(jobId){
                this.$refs.addForm.open(jobId);
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            refresh(){
                this.checkedList = [];
                this.search.status = 0;
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=0";
                this.$router.push('/job-vendor?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/job-vendor?' + url).catch(()=>{});

                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            getData(){
                this.loadding = true;
                jobVendorService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false;  });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.submitDistributeId = response.data.id;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: {
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }
</script>

<style scoped>

    ::-webkit-scrollbar{
        height: 12px;
        width: 0;
        background: #FFF;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #e0e0e0;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    .table-scroll thead tr:nth-child(3) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 91px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

</style>